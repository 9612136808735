import { AndroidService } from '@/service/Android';
import { StorageService } from '@/service/StorageService';

export default function useMacros() {
  const user_id = StorageService.getUserId();
  const country = StorageService.getCountryCode();
  const app_id = 'incash'; //StorageService.get('app_key', true);
  const ga_id = AndroidService.getDeviceIdentifiers().ga_id;

  return {
    user_id,
    country,
    app_id,
    ga_id,
  };
}
